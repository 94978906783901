import { Box, Card, Grid, Link, Typography } from '@mui/joy'
import { SxProps } from '@mui/material'
import { AppLogo } from 'Components/AppLogo'
import { ReactNode, memo, useEffect } from 'react'

type LayoutContainerProps = {
  title?: string
  children?: ReactNode | undefined
}

export const LayoutContainer = memo(({ title, children }: LayoutContainerProps) => {
  useEffect(() => {
    document.title = `WLED+ | ${title || process.env.REACT_APP_SITE_DESCRIPTION}`
  }, [])
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center' display='flex'>
      <Grid
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
        display='flex'
        sx={{ width: { xs: '100%', sm: '100%', md: 600, lg: 800, borderRadius: 10 } }}
      >
        <AppLogo
          url='/wledplus_icon.png'
          name='WLED'
          suffix='+'
          sx={{ marginTop: 5 }}
          size={70}
          href={process.env.REACT_APP_WLEDPLUS_PATH}
        />
        <Box sx={{ marginTop: 2 }}></Box>
        <Card
          sx={{
            p: 2,
            mt: 2,
            border: 0,
            width: { borderRadius: 10 },
          }}
        >
          {children}
        </Card>
        <Box sx={{ mt: 4, mb: 4 }}>
          <BottomNav />
        </Box>
      </Grid>
    </Grid>
  )
})

export const BottomNav = memo(() => {
  const urlPrefix = process.env.REACT_APP_WLEDPLUS_PATH == '/' ? '' : process.env.REACT_APP_WLEDPLUS_PATH
  const linkSx: SxProps = { color: '#FFFFFF99' }
  return (
    <Typography sx={{ color: '#FFFFFF33' }}>
      <Link href={`${urlPrefix}/privacy`} fontWeight={400} fontSize={14} sx={linkSx}>
        Privacy
      </Link>{' '}
      •{' '}
      <Link href={`${urlPrefix}/support`} fontWeight={400} fontSize={14} sx={linkSx}>
        Support
      </Link>{' '}
      •{' '}
      <Link href={`${urlPrefix}/feedback`} fontWeight={400} fontSize={14} sx={linkSx}>
        Feedback
      </Link>{' '}
      •{' '}
      <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`} fontWeight={400} fontSize={14} sx={linkSx}>
        Contact
      </Link>
    </Typography>
  )
})
