import { SxProps } from '@mui/material'
import { Box } from '@mui/system'
import { ReactNode, memo } from 'react'

type Props = {
  url: string
  scale?: number
  offset?: number
  frameUrl?: string
  shadow?: boolean
  borderRadius?: number
  sx?: SxProps
  children?: ReactNode | undefined
}

export const HeroScreenshot = memo(({ url, borderRadius = 0.0, frameUrl, sx, shadow = false, children }: Props) => {
  return (
    <Box
      sx={{
        filter: shadow ? 'drop-shadow(-30px -30px 10px #00000066)' : '',
      }}
    >
      <Box>
        <Box sx={{ ...sx }}>
          <img src={url} style={{ width: '100%', borderRadius: `${borderRadius * 100}%` }}></img>
        </Box>
        {frameUrl && (
          <Box
            sx={{
              background: `url(${frameUrl})`,
              backgroundSize: '100% 100%',
              backgroundPositionY: 'top',
              backgroundRepeat: 'no-repeat',
              left: 0,
              right: 0,
              top: 0,
              width: '100%',
              height: '100%',
              position: 'absolute',
              borderRadius: `${borderRadius * 100}%`,
            }}
          >
            {children}
          </Box>
        )}
      </Box>
    </Box>
  )
})
