import { useEffect } from 'react'

function WLEDPlusFeedback() {
  useEffect(() => {
    window.location.href = 'https://wledplus.canny.io/feedback'
  }, [])
  return <div />
}

export default WLEDPlusFeedback
