import { Box, Link, Stack, Typography } from '@mui/joy'
import { HeroFooter } from 'Components/HeroFooter'
import { HeroScreenshot } from 'Components/HeroScreenshot'
import { useEffect } from 'react'
import { AppLogo } from '../../Components/AppLogo'
import { BottomNav } from './Layout'

function WLEDPlus() {
  useEffect(() => {
    document.title = `WLED+ | ${process.env.REACT_APP_SITE_DESCRIPTION}`
  }, [])
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%', height: '100%' }}>
      <Stack flexDirection='column' alignItems='center' justifyContent='center' sx={{ height: '50%' }}>
        <AppLogo url='/wledplus_icon.png' name='WLED' suffix='+' vertical={true} />
        <Typography gutterBottom color='neutral' sx={{ mt: { xs: 4, md: 5 }, textAlign: 'center' }}>
          <Typography>
            Control your{' '}
            <Link href='https://kno.wled.ge/' color='neutral' sx={{ '&:hover': { textDecoration: 'none' } }}>
              <Typography variant='soft' sx={{ '&:hover': { backgroundColor: 'neutral.700' } }}>
                WLED
              </Typography>
            </Link>{' '}
            devices{' '}
          </Typography>
          <Typography>on iOS, Android and macOS</Typography>
        </Typography>
        <Stack sx={{ mt: 1 }} direction='row' justifyContent='space-between' alignItems='center'>
          <Box sx={{ mx: 1, my: 0.5 }}>
            <a href='https://apps.apple.com/us/app/wled/id6474789652'>
              <img alt='Download it on App Store' height={40} src='/badge_app_store.svg' />
            </a>
          </Box>
          <Box sx={{ mx: 1, my: 0.5 }}>
            <a href='https://play.google.com/store/apps/details?id=com.pixelheart.wledplus'>
              <img alt='Get it on Google Play' height={40} src='/badge_google_play.svg' />
            </a>
          </Box>
          {/* <Box sx={{ mx: 1, my: 0.5 }}>
            <img alt='Download it on Mac App Store' height={40} src='/badge_app_store_mac.svg' />
          </Box> */}
        </Stack>
      </Stack>
      <Box
        sx={{
          maskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
          WebkitMaskImage: 'linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0))',
          position: 'absolute',
          overflow: 'hidden',
          bottom: '5%',
          height: '45%',
          left: { xs: '5%', sm: '10%', lg: '20%' },
          width: { xs: '90%', sm: '80%', lg: '60%' },
        }}
      >
        <Box sx={{ left: '25%', width: '25%', position: 'absolute', top: '0%' }}>
          <HeroScreenshot
            url='/android_promo.00.home.png'
            frameUrl='/screenshot_frame_s22.png'
            scale={0.83}
            offset={0.02}
            sx={{
              paddingTop: '12%',
              paddingLeft: '1%',
              paddingRight: '2%',
            }}
          />
        </Box>
        <Box
          sx={{
            left: '47%',
            width: '25%',
            position: 'absolute',
            top: '10%',
          }}
        >
          <HeroScreenshot
            url='/android_promo.01.control.png'
            frameUrl='/screenshot_frame_pixel6.png'
            scale={0.77}
            offset={0.02}
            shadow={true}
            sx={{
              paddingTop: '12%',
              paddingLeft: '2%',
              paddingRight: '2%',
            }}
          />
        </Box>
        <Box sx={{ left: '0%', width: '100%', position: 'absolute', top: { xs: '30%', sm: '40%', lg: '50%' } }}>
          <HeroScreenshot
            url='/macos_promo.00.control.png'
            frameUrl='/screenshot_frame_macos.png'
            shadow={true}
            sx={{
              paddingTop: '1.5%',
              paddingLeft: '0.02%',
              paddingRight: '0.02%',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', position: 'absolute', bottom: 0, width: '100%', pb: 2 }}>
        <Box>
          <BottomNav />
        </Box>
        <HeroFooter />
      </Box>
    </Box>
  )
}

export default WLEDPlus
