import { CssBaseline, CssVarsProvider } from '@mui/joy'
import Home from 'Pages/Home'
import { DefaultTheme } from 'Themes/Default'
import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import WLEDPlus from './WLEDPlus'
import WLEDPlusFeedback from './WLEDPlus/Feedback'
import WLEDPlusPrivacy from './WLEDPlus/Privacy'
import WLEDPlusSupport from './WLEDPlus/Support'

const App = () => {
  const redirect = process.env.REACT_APP_REDIRECT
  useEffect(() => {
    if (redirect) {
      window.location.href = redirect
    }
  }, [redirect])
  return (
    <CssVarsProvider theme={DefaultTheme} defaultMode='dark'>
      <CssBaseline />
      <React.StrictMode>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter6Adapter}>
            {process.env.REACT_APP_DOMAIN == 'https://pixelheart.app' ? (
              <Routes>
                <Route index element={<Home />} />
                <Route path='wledplus'>
                  <Route index element={<WLEDPlus />} />
                  <Route path='privacy' element={<WLEDPlusPrivacy />} />
                  <Route path='feedback' element={<WLEDPlusFeedback />} />
                  <Route path='support' element={<WLEDPlusSupport />} />
                  <Route path='*' element={<WLEDPlus />} />
                </Route>
                <Route path='*' element={<Home />} />
              </Routes>
            ) : (
              <Routes>
                <Route index element={<WLEDPlus />} />
                <Route path='privacy' element={<WLEDPlusPrivacy />} />
                <Route path='feedback' element={<WLEDPlusFeedback />} />
                <Route path='support' element={<WLEDPlusSupport />} />
                <Route path='*' element={<WLEDPlus />} />
              </Routes>
            )}
          </QueryParamProvider>
        </BrowserRouter>
      </React.StrictMode>
    </CssVarsProvider>
  )
}
export default App
