import { Button, Link, List, ListItem, Typography } from '@mui/joy'
import { LayoutContainer } from '../Layout'

function WLEDPlusSupport() {
  const urlPrefix = process.env.REACT_APP_WLEDPLUS_PATH == '/' ? '' : process.env.REACT_APP_WLEDPLUS_PATH
  return (
    <LayoutContainer title='Support'>
      <Typography level='h3'>Support</Typography>
      <Typography level='body-sm' color='neutral'>
        <Typography fontWeight={700}>WLED+</Typography> officially supports devices running{' '}
        <Link href='https://github.com/Aircoookie/WLED/releases/'>WLED v0.14.0 and above</Link>. Devices running older
        version of WLED or custom folks might work as well, but some features might not function property. You should
        consider upgrading or switching to the official WLED release.
      </Typography>

      <Typography fontWeight={500}>Bugs & feedback</Typography>
      <Typography level='body-sm' color='neutral'>
        If you found a bug or have feature requests, please submit them via our feedback portal below or{' '}
        <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>email us</Link> directly.
      </Typography>
      <Link href={`${urlPrefix}/feedback`} sx={{}}>
        <Button color='primary'>Submit Feedback</Button>
      </Link>

      <Typography fontWeight={500}>Having issues?</Typography>
      <Typography level='body-sm' color='neutral'>
        If you are having trouble with connecting to you device in <Typography fontWeight={700}>WLED+</Typography>,
        please make secure device is accessible via it&apos;s web interface and it&apos;s functioning properly. You can
        find <Link href='https://kno.wled.ge/basics/faq/#connection-issues'>common issues</Link> on the WLED wiki.
      </Typography>

      <Typography fontWeight={500}>More resources</Typography>
      <List>
        <ListItem>
          <Typography level='body-sm' color='neutral'>
            <Link href='https://kno.wled.ge/basics/getting-started/'>WLED: Getting Started</Link>
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-sm' color='neutral'>
            <Link href='https://kno.wled.ge/basics/faq/'>WLED: Frequently Asked Questions</Link>
          </Typography>
        </ListItem>
        <ListItem>
          <Typography level='body-sm' color='neutral'>
            <Link href='https://kno.wled.ge/basics/tutorials/'>WLED: Tutorials</Link>
          </Typography>
        </ListItem>
      </List>
    </LayoutContainer>
  )
}

export default WLEDPlusSupport
