import { Box } from '@mui/joy'
import { AppLogo } from 'Components/AppLogo'
import { HeroContainer } from 'Components/HeroContainer'
import { HeroFooter } from 'Components/HeroFooter'

function Home() {
  return (
    <HeroContainer>
      <AppLogo
        name='WLED'
        suffix='+'
        url='/wledplus_icon.svg'
        href={process.env.NODE_ENV === 'production' ? 'https://wledplus.com' : '/wledplus'}
      />
      {/* <Box sx={{ mt: 10 }}>
        <InstagramButton url='https://instagram.com/pixelheart.app' />
      </Box> */}
      <Box sx={{ position: 'absolute', bottom: 0, pb: 2 }}>
        <HeroFooter />
      </Box>

      {/* <Box sx={{ marginTop: -2 }}>
        <Typography gutterBottom>Powered by WLED</Typography>
      </Box> */}
      {/* <Box sx={{ marginTop: 4 }}>
        <AppStoreButton url='https://apps.apple.com/us/app/wled-native/id6446207239'></AppStoreButton>
        <PlayStoreButton url='https://play.google.com/store/apps/details?id=ca.cgagnier.wlednativeandroid'></PlayStoreButton>
      </Box> */}
    </HeroContainer>
  )
}

export default Home
