import { Link, Typography } from '@mui/joy'
import { LayoutContainer } from '../Layout'

function WLEDPlusPrivacy() {
  return (
    <LayoutContainer title='Privacy Policy'>
      <Typography level='h3'>Privacy Policy</Typography>
      <Typography level='body-sm' color='neutral'>
        Last updated: <Typography variant='soft'>December 13, 2023</Typography>
      </Typography>
      <Typography level='body-sm' color='neutral'>
        This Privacy Policy describes how Pixel Heart LLC (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects,
        uses, and shares information when you use the WLED+ mobile/desktop application (&quot;the App&quot;). By using
        the App, you agree to the terms and conditions outlined in this Privacy Policy.
      </Typography>

      <Typography fontWeight={500}>Information Collection and Use</Typography>

      <Typography color='neutral'>1. Information We Collect</Typography>

      <Typography level='body-sm' color='neutral'>
        The WLED+ App does not collect any personally identifiable information. It is designed to be a client for
        controlling LED lights and devices on your local network that are running open-source{' '}
        <Link href={'https://wled.app/'}>
          <Typography level='body-sm' color='primary'>
            WLED
          </Typography>
        </Link>{' '}
        softwrare.
      </Typography>

      <Typography color='neutral'>2. Analytics</Typography>

      <Typography level='body-sm' color='neutral'>
        The App may collect analytics data related to app performance and stability through the use of{' '}
        <Link href='https://www.sentry.com/' color='primary'>
          Sentry
        </Link>{' '}
        . This data is collected anonymously and does not include any personally identifiable information.
      </Typography>

      <Typography fontWeight={500}>Information Sharing</Typography>

      <Typography level='body-sm' color='neutral'>
        We do not sell, trade, or otherwise transfer your information to outside parties. We may share anonymized
        analytics data with third-party service providers, such as Sentry, for the purpose of improving app performance
        and stability.
      </Typography>

      <Typography fontWeight={500}>Security</Typography>

      <Typography level='body-sm' color='neutral'>
        We take reasonable measures to protect the information collected through the App. However, please be aware that
        no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the
        absolute security of your data.
      </Typography>

      <Typography fontWeight={500}>Changes to This Privacy Policy</Typography>

      <Typography level='body-sm' color='neutral'>
        We may update this Privacy Policy from time to time. You are advised to review this page periodically for any
        changes. We will notify you of any changes by posting the new Privacy Policy on this page.
      </Typography>

      <Typography fontWeight={500}>Contact Us</Typography>

      <Typography level='body-sm' color='neutral'>
        If you have any questions or concerns about this Privacy Policy, please contact us at{' '}
        <Link href='mailto:support@pixelheart.app' color='primary'>
          support@pixelheart.app
        </Link>
        .
      </Typography>
    </LayoutContainer>
  )
}

export default WLEDPlusPrivacy
